// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';


const firebaseConfig = {
  apiKey: "AIzaSyA1iNRVIdTrgvJkMqGO4nlbPBBnR9LF2Ao",
  authDomain: "fir-test-98ee6.firebaseapp.com",
  projectId: "fir-test-98ee6",
  storageBucket: "fir-test-98ee6.appspot.com",
  messagingSenderId: "681610498454",
  appId: "1:681610498454:web:f5fbe5e8856a22625c4db7"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const db = getFirestore();