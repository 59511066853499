import { db } from './firebase';
import { collection,addDoc,getDocs,query,where,updateDoc,getDoc,deleteDoc,doc } from 'firebase/firestore';
import { async } from '@firebase/util';

const collectionName = 'Persons';

export const savePersonName =(name)=>{
    addDoc(collection(db,collectionName),{name})
}
export const updatePersonName=async (id,name) =>{
    await updateDoc(doc(db, collectionName, id), {name})
}
export const deletePersonName=async(id)=>{
    await deleteDoc(doc(db, collectionName, id));
}
export const getPersons = async ()=>{
  const result = await getDocs(query(collection(db,collectionName)));
  return result;
}

