import { async } from '@firebase/util';
import { useState, useEffect } from 'react';
import { savePersonName, getPersons, deletePersonName } from './application/api'

function App() {
  const [personName, setPersonName] = useState(null);
  const [persons, setPersons] = useState(null);
  const [personId, setPersonId] = useState(null);

  const savePerson = async () => {
    savePersonName(personName);
    await getPersonsData();
  }
  const delPerson = async () => {
    await deletePersonName(personId)
    await getPersonsData();
  }
  useEffect(() => {
    getPersonsData();
  }, [])
  const getPersonsData = async () => {
    const p = await getPersons();
    //console.log(p.docs[0].data());
    setPersons(p.docs);
  }
  const delSelected = async (id) => {
    await deletePersonName(id);
    await getPersonsData();
  }
  return (
    <div className="App">
      <input type="text" onChange={e => setPersonId(e.target.value)} placeholder="setPersonId" /> <input type="text" onChange={e => setPersonName(e.target.value)} placeholder="setPersonName" /> <button onClick={savePerson}> Salvar</button><button onClick={delPerson}> Eliminar</button>
      {
        persons && persons.map(p => <div><p align="center" > {p.id} - {p.data().name} <a href='#' onClick={e => delSelected(p.id)}>elim</a></p></div>)
      }
    </div>
  );
}

export default App;